.environment-independent {
  background: var(--darkbaiege);
}
.environment-independent .environment-independent-container {
  display: flex;
  align-items: stretch;
  gap: 20px;
}
.environment-independent .environment-independent-container .environment-left {
  width: 60%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.environment-independent .environment-independent-container .environment-left .tree-count {
  display: flex;
  align-items: center;
  gap: 5px;
}
.environment-independent .environment-independent-container .environment-right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.environment-independent .environment-independent-container .environment-right .environment-img {
  border-radius: 10px;
  background: url("../Img/ourwork/en-2.webp");
  width: 80%;
  aspect-ratio: 1/1;
}

.environment-independent2 .environment-independent2-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.environment-independent3 {
  background: var(--darkbaiege);
}
.environment-independent3 .environment-independent3-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.environment-independent3 .environment-independent3-container .environment3-main {
  width: 100%;
  display: flex;
  gap: 20px;
}
.environment-independent3 .environment-independent3-container .environment3-main .environment3-left {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.environment-independent3 .environment-independent3-container .environment3-main .environment3-right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.environment-independent3 .environment-independent3-container .environment3-main .environment3-right .environment3-img {
  border-radius: 10px;
  background: url("../Img/ourwork/en-3.webp");
  width: 80%;
  aspect-ratio: 1/1;
}
.environment-independent3 .environment-independent3-container .environment3-stats {
  width: 100%;
  display: flex;
  gap: 20px;
}
.environment-independent3 .environment-independent3-container .environment4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.environment-independent3 .environment-independent3-container .environment4 p,
.environment-independent3 .environment-independent3-container .environment4 h2 {
  text-align: center;
}

.environment-carousel .environment-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.environment-carousel .environment-carousel-container .carousel-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
}

@media only screen and (max-width: 800px) {
  .environment-independent .environment-independent-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .environment-independent .environment-independent-container .environment-left {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .environment-independent .environment-independent-container .environment-right {
    width: 100% !important;
  }
}
@media only screen and (max-width: 800px) {
  .environment-independent3 .environment-independent3-container {
    display: flex;
    flex-direction: column;
  }
  .environment-independent3 .environment-independent3-container .environment3-main {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .environment-independent3 .environment-independent3-container .environment3-main .environment3-left {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .environment-independent3 .environment-independent3-container .environment3-main .environment3-right {
    width: 100% !important;
  }
  .environment-independent3 .environment-independent3-container .environment3-stats {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
  .environment-independent3 .environment-independent3-container .environment3-stats .stats1 {
    width: 95%;
  }
  .environment-independent3 .environment-independent3-container .environment3-stats .stats2 {
    width: 95%;
  }
}/*# sourceMappingURL=environment.css.map */