.footer-parent {
    padding: 10px 0px 30px 0px;
    background: rgb(20, 20, 20);

    .footer-container {

        max-width: var(--swl_d);
        height: 100%;
        margin: auto;
        padding: 10px 20px 0px 20px;

        .footer-top {
            display: flex;
            justify-content: space-around;

            .col {
                width: 25%;
                // width: 200px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .title {
                    color: var(--white);
                    // color: rgba(255, 255, 255, 0.342);
                    padding-bottom: 10px;
                }

                a {
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.342);
                    font-size: 14px;

                    &:hover {
                        color: var(--darkorange);
                    }
                }
            }

            .column {
                width: 25%;
                // width: 200px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .title {
                    color: var(--white);
                    // color: rgba(255, 255, 255, 0.342);
                    padding-bottom: 10px;
                    font-weight: bold!important;
                }

                a {
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.342);
                    font-size: 14px;

                    &:hover {
                        color: var(--darkorange);
                    }
                }
            }

            // {/* column 1 */}
            .col4 {
                .icon-address {
                    display: flex;
                    gap: 20px;


                    .f-icon1 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                        font-size: 20px;
                        border-radius: 50%;
                        // background-color: var(--glass);
                        align-items: center;
                        padding: 8px;
                        color: var(--darkorange);
                    }
                }

            }

        }
    }
}

// Footer Botttom section
.footer-bottom-parent {
    background-color: black;
    padding: 30px 10px;
    height: auto;

    .footer-bottom-container {
        height: 100%;
        max-width: var(--swl_d) !important;
        margin: auto !important;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        // padding: auto 10;


        .social {
            display: flex;
            gap: 30px;
            font-size: 25px;


            .f-icon {
                color: var(--text) !important;

                &:hover {
                    color: var(--darkorange) !important;
                }

            }


        }

        .reserved {
            text-align: center;
            color: rgba(255, 255, 255, 0.356);
            a{
                color: rgb(255, 255, 255);
                text-decoration: none!important;
            }

        }
    }
}




@media only screen and (max-width: 600px) {
    .footer-parent {
        height: auto;
        .footer-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .footer-top {
                flex-direction: column;
                width: 100%;
                align-items: center;
                gap: 10px;

                .col {
                    align-items: center;

                    width: 100%!important;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.129);
                    padding: 20px 0px;
                }

                .column {
                    width: 100%!important;
                    padding: 20px 0px;
                    // border-top: 1px solid rgba(255, 255, 255, 0.129);
                }
            }
        }
    }

.footer-bottom-parent{
    // padding: 2px;
height: auto;
    .footer-bottom-container{
        overflow: hidden;
        text-align: center;
        height: auto;
    }
}

}


@media only screen and (max-width: 1000px) {
  .footer-parent{
    .footer-container 
    {
      display: flex;
      flex-wrap: wrap;
      .footer-top
      {
        flex-wrap: wrap;
        width: 100%;
        gap:20px;
    
        .col 
        {
          width: 45%;
        }
     .column{
        width: 45%;
     }
      }
      .footer-bottom 
      {
        
        width: 100%;
      }
    }
}
}


@media only screen and (max-width: 530px) {
    .footer-parent{
        .footer-container 
        {

            .footer-top
            .column{
                width: 100%!important;
                padding: auto 50px!important;
             }
            }
        }
    
}