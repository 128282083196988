
.smallcard-container {
  display: flex;
  align-items: center;
  width: 800px;
  height: 150px;
  background: var(--darkbaiege);
  padding: 10px;
  border-radius: 15px;
  &:last-child {
    border: none;
  }

  .left-img {
    background: url("https://img.freepik.com/free-photo/night-sky-glows-with-iridescent-deep-space-generative-ai_188544-11285.jpg?w=1380&t=st=1702447923~exp=1702448523~hmac=a203ab7e1518871b73deb0ae6030abfc44f187af8edfe4da6322e8023fc9a014");
    height: auto;
    aspect-ratio: 5 / 3;
    border-radius: 10px;
    height: 100%;
  }

  .smallcard-right {
    width: 60%;
    height: 100%;
    // background: #00000028;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    .category {
      font-size: 12px;
      color: var(--darkorange);
      font-weight: 700;
    }
h3 
{
    color: var(--black);
}
    .published {
      font-size: 12px;
      bottom: 0;

      span {
        color: var(--darkorange);
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .smallcard-container {
    padding: 5px;
    width: 100% !important;
  }
}
@media only screen and (max-width: 800px) {
  .smallcard-container {
    height: 130px;
  }
}
@media only screen and (max-width: 500px) {
  .smallcard-container {
    height: auto;
  }
  .left-img {
    width: 130px ;
  }
}
