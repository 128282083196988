.counter-parent {
  height: 100%;
  background: var(--darkbaiege);
}
.counter-parent .counter-container {
  max-width: var(--swl_d);
  display: flex;
  margin: auto;
  padding: 0px 50px;
  text-align: center;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.counter-parent .counter-container h2 {
  padding-bottom: 50px;
}
.counter-parent .counter-container .counter_wrapper .Counter_top {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.counter-parent .counter-container .counter_wrapper .Counter_top .group1,
.counter-parent .counter-container .counter_wrapper .Counter_top .group2,
.counter-parent .counter-container .counter_wrapper .Counter_top .group3,
.counter-parent .counter-container .counter_wrapper .Counter_top .group4 {
  padding: 30px;
  width: 250px;
  border-radius: 10px;
  background: white;
}
.counter-parent .counter-container .counter_wrapper .Counter_top span {
  font-size: 40px;
  color: var(--darkorange);
  font-weight: 600;
}
.counter-parent .counter-container .counter_wrapper .Counter_top .Counter_sufix {
  font-size: 30px;
}
@media only screen and (max-width: 1150px) {
  .counter-parent .counter-container {
    height: 400px;
  }
  .counter-parent .counter_wrapper {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .counter-parent .counter_wrapper .Counter_top {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center !important;
  }
  .counter-parent .counter_wrapper .Counter_top .group1,
  .counter-parent .counter_wrapper .Counter_top .group2,
  .counter-parent .counter_wrapper .Counter_top .group3,
  .counter-parent .counter_wrapper .Counter_top .group4 {
    width: 400px !important;
    gap: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  .counter-parent .counter-container {
    height: 400px;
  }
  .counter-parent .counter_wrapper {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .counter-parent .counter_wrapper .Counter_top {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center !important;
  }
  .counter-parent .counter_wrapper .Counter_top .group1,
  .counter-parent .counter_wrapper .Counter_top .group2,
  .counter-parent .counter_wrapper .Counter_top .group3,
  .counter-parent .counter_wrapper .Counter_top .group4 {
    width: 300px !important;
  }
}
@media only screen and (max-width: 750px) {
  .counter-parent .counter-container {
    height: auto;
  }
  .counter-parent .counter_wrapper {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .counter-parent .counter_wrapper .Counter_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 30px;
    justify-content: center;
  }
  .counter-parent .counter_wrapper .Counter_top .group1,
  .counter-parent .counter_wrapper .Counter_top .group2,
  .counter-parent .counter_wrapper .Counter_top .group3,
  .counter-parent .counter_wrapper .Counter_top .group4 {
    width: 250px !important;
  }
}

.drushti-slider .drushtislider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 30px;
}
.drushti-slider .drushtislider-container .slider-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  overflow: hidden !important;
  margin: 0px 10px !important;
}
.drushti-slider .drushtislider-container h2 {
  padding-bottom: 30px;
}

.carousel-container {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px !important;
}
.carousel-container .mySwiper {
  width: 100%;
  height: 100%;
}
.carousel-container .mySwiper .slide {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.carousel-container .mySwiper .slideabout6 {
  background: url("../Img/gallery-page/26.webp");
}
.carousel-container .mySwiper .slideabout7 {
  background: url("../Img/gallery-page/28.webp");
}
.carousel-container .mySwiper .slideabout8 {
  background: url("../Img/gallery-page/31.webp");
}
.carousel-container .mySwiper .slideabout9 {
  background: url("../Img//gallery-page/03.webp");
}
.carousel-container .mySwiper .slideabout10 {
  background: url("https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.webp?w=1380&t=st=1702013222~exp=1702013822~hmac=1eeb269bf9beede05a5061b18463225b2248f7a115910d2acd70c55b2a4ac3b4");
}
.carousel-container .mySwiper .slideabout11 {
  background: url("https://img.freepik.com/free-photo/beautiful-shot-small-lake-with-wooden-rowboat-focus-breathtaking-clouds-sky_181624-2490.webp?w=1380&t=st=1702013243~exp=1702013843~hmac=cdad73dbe4b70435c46a3ff45b28da4ee4727305c92a4ba9c786c51f4363ef6a");
}
.carousel-container .mySwiper .slideabout12 {
  background: url("../Img//gallery-page/03.webp");
}
.carousel-container .mySwiper .slideabout13 {
  background: url("https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.webp?w=1380&t=st=1702013222~exp=1702013822~hmac=1eeb269bf9beede05a5061b18463225b2248f7a115910d2acd70c55b2a4ac3b4");
}
.carousel-container .mySwiper .slideabout14 {
  background: url("https://img.freepik.com/free-photo/beautiful-shot-small-lake-with-wooden-rowboat-focus-breathtaking-clouds-sky_181624-2490.webp?w=1380&t=st=1702013243~exp=1702013843~hmac=cdad73dbe4b70435c46a3ff45b28da4ee4727305c92a4ba9c786c51f4363ef6a");
}
.carousel-container .mySwiper .slideabout15 {
  background: url("../Img//gallery-page/03.webp");
}
.carousel-container .mySwiper .slideabout16 {
  background: url("https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.webp?w=1380&t=st=1702013222~exp=1702013822~hmac=1eeb269bf9beede05a5061b18463225b2248f7a115910d2acd70c55b2a4ac3b4");
}
.carousel-container .mySwiper .slideabout17 {
  background: url("https://img.freepik.com/free-photo/beautiful-shot-small-lake-with-wooden-rowboat-focus-breathtaking-clouds-sky_181624-2490.webp?w=1380&t=st=1702013243~exp=1702013843~hmac=cdad73dbe4b70435c46a3ff45b28da4ee4727305c92a4ba9c786c51f4363ef6a");
}/*# sourceMappingURL=drushtitemp.css.map */