.drushti-parent {
  background-color: var(--darkbaiege);

  .drushti-container {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dbox-parent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      height: auto;
      width: 100%;

      .dimg1-box {
        height: 250px !important;
        width: 250px !important;

        background: url("../Img/gallery-page/media/drushti1.webp");
        border-radius: 10px;

        .doverlay {
          height: 100%;
          width: inherit;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.829));
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding: 10px;
          h3 {
            color: white;
            text-align: center;
          }
        }
      }
      .second-img{
        background: url("../Img/gallery-page/media/drushti2.webp");
        
    }
    .third-img{
      background: url("../Img/gallery-page/media/drushti3.webp");
      
  }
    .fourth-img{
      background: url("../Img/gallery-page/media/drushti4.webp");
      
  }
  }

}

@media only screen and (max-width: 1100px) {
  .drushti-container {
    max-width: 600px !important;
  }
}
}



