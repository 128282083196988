.toolbox-wrapper 
{
    position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 10001231230;
    display: flex;
    justify-content: center;
    align-items: center;

    button 
    {
        padding: 10px;
        font-size: 20px;
        cursor: pointer;
        color: var(--white);
        border-radius: 5px;
        transition: var(--trans);

        &:hover 
        {
            transform: translateY(-5px);
        }
    }
}