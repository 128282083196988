.homegallery-parent {
  background-color: var(--darkbaiege);

  .homegallery-container {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2{
        text-align: center;
    }

    .box-parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      height: auto;
      aspect-ratio: 12 / 5;
      width: 100%;

      .box1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .box2 {
        grid-area: 1 / 2 / 2 / 3;
      }

      .box3 {
        grid-area: 2 / 1 / 3 / 3;
      }

      .box4 {
        grid-area: 1 / 3 / 3 / 5;
      }

      .img-box1 {
        background: url("../Img/gallery-page/20.webp");
        border-radius: 10px;

        .overlay {
          height: 100%;
          width: inherit;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.829));
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding: 20px;
          h3 {
            color: white;
          }
        }
      }
      .img2 {
        background: url("../Img/video.webp");
      }
      .img3 {
        background: url("../Img/gallery-page/07.webp");
      }
      .img4 {
        background: url("../Img/gallery-page/24.webp");
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .homegallery-parent {
    .homegallery-container {
      padding: 0px 20px !important;
      .box-parent {
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .homegallery-parent {
    .homegallery-container {
      .box-parent {
        display: flex;
        flex-direction: column;
        aspect-ratio: none;

        .img-box1 {
          height: 200px;
        }
      }
    }
  }
}
