.vision {
  padding: 50px 0px;
  background-color: var(--darkbaiege);
}
.vision .vision-container {
  display: flex;
  align-items: stretch !important;
  --gap: 20px;
  gap: var(--gap);
}
.vision .vision-container .vision-left {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.vision .vision-container .vision-right {
  width: 50%;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--baiege);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.105);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

@media only screen and (max-width: 1200px) {
  .vision-container {
    flex-direction: column;
  }
  .vision-container .vision-left {
    width: 100% !important;
  }
  .vision-container .vision-right {
    width: 100% !important;
    align-items: center !important;
  }
  .vision-container .vision-right ul {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
}
.founder {
  height: auto;
  padding: 50px 0px;
  background-color: var(--darkbaiege);
}
.founder .founder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.founder .founder-container h2 {
  padding-bottom: 30px;
}
.founder .founder-container .founder-cards {
  display: flex;
  align-items: stretch !important;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
.founder .founder-container .founder-cards > * {
  height: 1000px;
}

.about-slider .aboutslider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 30px;
}
.about-slider .aboutslider-container .slider-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  overflow: hidden !important;
  margin: 0px 10px !important;
}
.about-slider .aboutslider-container h2 {
  padding-bottom: 30px;
}/*# sourceMappingURL=about.css.map */