.contact-parent .contact-container {
  display: flex;
  gap: 50px;
  height: auto;
}
.contact-parent .contact-container .contact-left,
.contact-parent .contact-container form {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  gap: 20px;
  background: var(--darkbaiege);
}
.contact-parent .contact-container .contact-left .input-row,
.contact-parent .contact-container form .input-row {
  display: flex;
  width: 100%;
  gap: 20px;
}
.contact-parent .contact-container .contact-left input:not([type=submit]),
.contact-parent .contact-container .contact-left textarea,
.contact-parent .contact-container form input:not([type=submit]),
.contact-parent .contact-container form textarea {
  width: 100%;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 15px 20px;
  border: 2px solid transparent;
  transition: var(--trans);
  background: var(--baiege);
  resize: none;
}
.contact-parent .contact-container .contact-left input:not([type=submit]):focus,
.contact-parent .contact-container .contact-left textarea:focus,
.contact-parent .contact-container form input:not([type=submit]):focus,
.contact-parent .contact-container form textarea:focus {
  border: 2px solid var(--darkorange);
}
.contact-parent .contact-container .contact-right {
  width: 80%;
  background: var(--darkbaiege);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  padding: 20px;
}
.contact-parent .contact-container .contact-right .contact-right-div {
  display: flex;
  gap: 20px;
  color: black;
  align-items: center;
  background-color: var(--baiege);
  width: 100%;
  padding: 20px 30px;
  border-radius: 10px;
}
.contact-parent .contact-container .contact-right .contact-right-div:last-child {
  align-items: flex-start !important;
}
.contact-parent .contact-container .contact-right .contact-right-div span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  background: var(--darkorange);
}
.contact-parent .contact-container .contact-right .contact-right-div span svg {
  font-size: 20px;
  color: var(--white);
}

@media only screen and (max-width: 1150px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    height: auto !important;
  }
}
@media only screen and (max-width: 750px) {
  .contact-container {
    width: 100vw !important;
  }
  .contact-container .contact-left {
    width: 95% !important;
  }
  .contact-container .contact-left form {
    width: 100% !important;
  }
  .contact-container .contact-left input:not([type=submit]),
  .contact-container .contact-left textarea {
    width: 80% !important;
  }
  .contact-container .contact-right {
    width: 100% !important;
  }
  .contact-container .contact-right .contact-right-div {
    padding: 20px 10px !important;
  }
}
@media only screen and (max-width: 450px) {
  .contact-container .contact-left {
    width: 100% !important;
  }
  .contact-container .contact-left input:not([type=submit]),
  .contact-container .contact-left textarea {
    width: 100% !important;
  }
  .contact-container .contact-right {
    width: 100% !important;
  }
}/*# sourceMappingURL=contact.css.map */