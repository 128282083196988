.pagenot .pagenot-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.pagenot .pagenot-container .oops {
  width: 100%;
  height: 250px;
  background: url("../Img/SVG/404.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  .oops {
    width: 100%;
    height: 150px !important;
    background: url("../Img/SVG/404.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}/*# sourceMappingURL=pagenot.css.map */