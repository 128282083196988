:root {
  --black: #000;
  --text: #363636b7;
  --white: #fff;
  --darkorange: #ff6400;
  --mainorange: #f18425;
  --baiege: #fef7ef;
  --darkbaiege: #f2e7d9;
  --swl_d: 1200px;
  --lwl_d: 1400px;
  --trans: all 0.33s cubic-bezier(0.42, 0.97, 0.52, 1.49);
  --trans2: all 1s cubic-bezier(0.42, 0.97, 0.52, 1.49);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  // border: 1px solid blue;
  font-family: "Inter", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  width: 100vw;
  width: 100svw;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: var(--baiege);
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 30px;
  // padding: 10px 0px;
}

h3 {
  font-size: 24px;
}

h1,
h2,
h3,
h4 {
  text-transform: capitalize !important;
  span {
    color: var(--darkorange);
  }
}

P {
  font-size: 16px;
  color: var(--text);
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (max-width: 820px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 420px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}

.parent {
  width: 100vw;
  padding: 80px 0px;

  .container-small {
    height: 100%;
    max-width: var(--swl_d);
    margin: auto;
    padding: 0px 20px;
  }

  .container-large {
    height: 100%;
    max-width: var(--lwl_d);
    margin: auto;
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .container-large,
  .container-small {
    padding: 0px 10px !important;
  }
}

.btn-active {
  text-decoration: none;
  color: var(--white);
  background: linear-gradient(90deg, var(--darkorange), red);
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 100px;
  transition: var(--trans2);
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  border: none;

  span {
    display: flex;
    align-items: center;
    transition: var(--trans2);
    font-size: 20px;
  }

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
    transform: scale(1.05);
    transition: var(--trans);

    span {
      transform: translateX(10px);
      transition: var(--trans);
    }
  }
}
.btn-unactive {
  text-decoration: none;
  color: var(--black);
  border: 1px solid red !important;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 100px;
  transition: var(--trans2);
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background: transparent;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
    background: linear-gradient(90deg, var(--darkorange), red);
    transform: scale(1.05);
    transition: var(--trans);
    color: white;
  }
}

.glass {
  background: rgba(132, 131, 131, 0.484);
  backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 0.248);
}

.bg-img {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}

.cssimg {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: white !important;
  opacity: 0.8 !important;
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  border-radius: 10px !important;
  background-color: var(--darkorange) !important;
  transition: var(--trans);
}
.loadmore-btn {
  margin-bottom: 15px;
}

.btn2 {
  text-decoration: none;
  color: #000;
  transition: var(--trans);
  &:hover {
    text-decoration: underline;
  }
}
.shine {
  position: relative;
  overflow: hidden;
  &::before {
    background: linear-gradient(
      to right,
      fade_out(#fff, 1) 0%,
      fade_out(#fff, 0.7) 100%
    );
    content: "";
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
  }

  &:hover,
  &:focus {
    &::before {
      animation: shine 0.85s;
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }
}
a {
  text-decoration: none;
}
.work-carousel-parent {
  .work-carousel-container {
    height: 500px;
  }
}

.card-list {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--baiege);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.105);

  h3 {
    padding-bottom: 20px;
  }
}

ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
    position: relative;
    margin-left: 20px;
    font-size: 16px;
    color: var(--text);

    &::before {
      display: block;
      content: "";
      background-color: var(--darkorange);
      position: absolute;
      width: 6px;
      margin-top: 3px;
      height: 6px;
      border-radius: 50px !important;
      left: -20px;
      top: 5px;
    }
  }
}

// carousel
.carousel-container-about {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px !important;

  .mySwiper {
    width: 100%;
    height: 100%;

    .slide {
      width: 100%;
      height: 100%;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    .slideabout1 {
      background: url("../src/Img/about.webp") !important;
    }

    .slideabout2 {
      background: url("../src/Img/education.webp") !important;
    }

    .slideabout3 {
      background: url("../src/Img/empower.webp") !important;
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
