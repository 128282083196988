.card-body {
  width: 330px;
  height: 100% !important;
  padding: 15px 15px 15px 15px;
  background-color: var(--baiege);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.105);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.card-body .card-img {
  width: 300px;
  height: 170px;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 5px;
}
.card-body .bottom-card {
  height: calc(100% - 190px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.card-body .bottom-card .bottom-card-top {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.card-body .bottom-card .bottom-card-top h3 {
  padding: 10px 0px;
  text-align: left;
  font-size: 125% !important;
}
.card-body .bottom-card .bottom-card-top p {
  padding: 0px 0px 15px 0px;
  text-align: justify;
}/*# sourceMappingURL=complexcard.css.map */