.largeblogcard-container {
  width: 500px;
  padding: 5px 15px;
}
.largeblogcard-container .largecard-img {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 10px;
}
.largeblogcard-container .category-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.171);
}
.largeblogcard-container .category-box .category {
  font-size: 12px;
  color: var(--darkorange);
  font-weight: 700;
}
.largeblogcard-container .category-box .published {
  font-size: 12px;
}
.largeblogcard-container .category-box .published span {
  color: var(--darkorange);
  font-weight: 700;
}

@media only screen and (max-width: 1200px) {
  .largeblogcard-container {
    width: 100% !important;
    background: red !important;
  }
}/*# sourceMappingURL=largeblogcard.css.map */