.water-independent {
  background: var(--darkbaiege);
}
.water-independent .water-independent-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.water-independent .water-independent-container .water-left {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.water-independent .water-independent-container .water-right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.water-independent .water-independent-container .water-right .water-img {
  border-radius: 10px;
  background: url("../Img/water/jalsakshrta.jpg");
  width: 80%;
  aspect-ratio: 1/1;
}

.water-conservation-slider .water-conservation-slider-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.water-conservation-slider .water-conservation-slider-container .water-slider-box {
  width: 100%;
  height: auto;
  background: var(--darkbaiege);
  padding-bottom: 0px;
}

.water-slider .water-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.water-slider .water-slider-container .slider-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  overflow: hidden !important;
  margin: 0px 10px !important;
}/*# sourceMappingURL=water_conservation.css.map */