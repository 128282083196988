.simple-card 
{
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--baiege);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.105);
    display: flex;
    flex-direction: column;
    justify-content: center;
p 
{
    text-align: justify;
    padding-top: 20px;
}
  
}
@media only screen and (max-width: 1000px) {
    .simple-card 
    {
        h2 
        {
            width: 100%;
            text-align: center !important;
            padding-bottom: 20px;
        }
    }
}