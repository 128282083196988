.art-independent {
    background: var(--darkbaiege);
    .art-independent-conatiner{
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        .art-left {
            width: 60%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
        }

        .art-right {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap:20px;

            .art-img {
                border-radius: 10px;
                background: red;
                width: 80%;
                aspect-ratio: 1 / 1;
            }

        }


    }
}



.art-year-slider{
    .art-year-slider-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;

        .art-slider-box{
            width: 100%;
            height: auto;
            background: var(--darkbaiege);
            padding-bottom: 0px;
          
        }
    }
}

.art-is3{
    background-color: var(--darkbaiege);
    
    .art-is3-container{
        display: flex;
        gap: 20px;

    }

}

.art-slider{

    .art-slider-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .slider-box{
            width: 100%;
            height: auto;
            aspect-ratio: 2 / 1;
            border-radius: 10px;
            overflow: hidden !important;
            margin: 0px 10px !important;
        }
    }
}