.progresscard-wrapper {
  height: auto;
  background-color: var(--darkbaiege);
}
.progresscard-wrapper .progress-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.progresscard-wrapper .progress-card-container h2 {
  padding-bottom: 30px;
}
.progresscard-wrapper .progress-card-container .stats-container {
  width: 100%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  gap: 20px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top.stats-bottom,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom.stats-bottom {
  flex-direction: row-reverse;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image1,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image1 {
  background: url("../Img/gallery-page/03.webp");
  height: 100%;
  width: 60%;
  border-radius: 10px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image2,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image2 {
  background: url("../Img/gallery-page/26.webp");
  height: 100%;
  width: 60%;
  border-radius: 10px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards {
  height: 100%;
  width: 40%;
  background-color: var(--baiege);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .progress-bar,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .progress-bar {
  width: 100%;
  height: auto;
  margin-top: 30px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .progress-bar .progress-bg,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .progress-bar .progress-bg {
  height: 5px;
  width: 100%;
  border-radius: 100px;
  background: var(--darkbaiege);
  position: relative;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .progress-bar .progress-bg .progress-line,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .progress-bar .progress-bg .progress-line {
  height: inherit;
  background: var(--darkorange);
  border-radius: 100px;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .progress-bar .progress-bg .progress-dot,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .progress-bar .progress-bg .progress-dot {
  position: absolute;
  top: 50%;
  background: var(--darkorange);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.363);
  transform: translate(-50%, -50%);
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .progress-bar .progress-bg .progress-dot::before,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .progress-bar .progress-bg .progress-dot::before {
  content: attr(progress);
  transform: translate(-30%, -150%);
  font-weight: 600;
  color: var(--darkorange);
  display: block;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-current,
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-target,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-current,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-target {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-current.stats-target,
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-target.stats-target,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-current.stats-target,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-target.stats-target {
  align-items: flex-end;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-current .stats-indicator,
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-target .stats-indicator,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-current .stats-indicator,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-target .stats-indicator {
  font-size: 14px;
  color: var(--text);
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-current .stats-values,
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-target .stats-values,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-current .stats-values,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-target .stats-values {
  font-weight: 700;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-numbers .stats-arrow,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-numbers .stats-arrow {
  display: flex;
  font-size: 30px;
  opacity: 0.1;
}
.progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards .stats-buttons,
.progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards .stats-buttons {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

@media only screen and (max-width: 1050px) {
  .progresscard-wrapper .progress-card-container .stats-container {
    height: auto;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom {
    height: 450px !important;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image {
    width: 50%;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards {
    width: 50%;
  }
}
@media only screen and (max-width: 700px) {
  .progresscard-wrapper .progress-card-container .stats-container .stats-top,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom {
    flex-direction: column !important;
    height: auto;
    width: 100% !important;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image,
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-cards,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-cards {
    height: 400px;
    width: 100% !important;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image {
    width: 100% !important;
  }
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image1,
  .progresscard-wrapper .progress-card-container .stats-container .stats-top .stats-image2,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image1,
  .progresscard-wrapper .progress-card-container .stats-container .stats-bottom .stats-image2 {
    width: 100% !important;
    height: 250px !important;
  }
}/*# sourceMappingURL=progresscard.css.map */