.volunteer-parent {
  .volunteer-container {
    display: flex;
    gap: 50px;
    align-items: center;

    .volunteer-left,
    form {
      width: 720px;
      height: 100%;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      input:not([type="submit"]),
      textarea {
        width: 60%;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 15px 20px;
        border: 2px solid transparent;
        transition: var(--trans);
        background: var(--darkbaiege);

        &:focus {
          border: 2px solid var(--darkorange);
        }
      }
    }

    .volunteer-right {
      width: 480px;
      background: var(--darkbaiege);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
      border-radius: 5px;

      padding: 20px;

      .volunteer-right-div {
        display: flex;
        gap: 20px;
        color: black;
        align-items: center;
        background-color: var(--baiege);
        width: 100%;
        padding: 20px 30px;
        border-radius: 10px;

        &:last-child {
          align-items: flex-start !important;
        }

        span {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 10px;
          background: var(--darkorange);

          svg {
            font-size: 20px;
            color: var(--white);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .volunteer-container {
    flex-direction: column;
    align-items: center;
    height: auto !important;
  }
}

@media only screen and (max-width: 750px) {
  .volunteer-container {
    width: 100vw !important;
    .volunteer-left {
      width: 95% !important;
      form {
        width: 100% !important;
      }

      input:not([type="submit"]),
      textarea {
        width: 80% !important;
      }
    }

    .volunteer-right {
      width: 100% !important;

      .volunteer-right-div {
        padding: 20px 10px !important;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .volunteer-container {
    .volunteer-left {
      width: 100% !important;

      input:not([type="submit"]),
      textarea {
        width: 100% !important;
      }
    }

    .volunteer-right {
      width: 100% !important;
    }
  }
}
