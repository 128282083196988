.pagetop-body {
  width: 100%;
  height: 350px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: relative;
}
.pagetop-body .pagetop-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagetop-body .pagetop-container h1 {
  color: var(--white);
  z-index: 100;
  text-align: center !important;
}
.pagetop-body .overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.981));
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}/*# sourceMappingURL=pagetop.css.map */