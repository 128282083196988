.independent-empower {
  background: var(--darkbaiege);
  padding: 50px 0px;

  .independent-empower-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .independent-left {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .independent-right {
      width: 30%;
      position: relative;

      .right-box {
        width: 90%;
        aspect-ratio: 1 / 1;
        background: url("../Img/ourwork/ep-2.webp");
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .independent-empower-container {
    .independent-left {
      width: 60%;
      h2 {
        text-align: center;
      }
    }

    .independent-right {
      width: 40%;
      position: relative;
    }
  }
}
@media only screen and (max-width: 800px) {
  .independent-empower-container {
    flex-direction: column-reverse;

    .independent-left {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      h2,
      p {
        text-align: center;
      }
    }

    .independent-right {
      width: 100% !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .right-box {
        max-width: 600px;
        aspect-ratio: 1 / 1;
        background: red;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
}

.independent-her {
  .independent-her-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .independent-her-cards {
      width: 100%;
      display: flex;
      align-items: stretch;
      gap: 20px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .independent-her {
    .independent-her-container {
      .independent-her-cards {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 20px;
      }
    }
  }
}

.independent-disabled {
  background: var(--darkbaiege);
  padding: 80px 0px;

  .independent-disable-container {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;

    .disabled-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:30px;
      padding-bottom: 50px;
      
      p{
        text-align:center;
      }
    }
    .disabled-bottom {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap:50px;

      .disabled-left {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .left-box{
            border-radius: 10px;
            background: url("../Img/ourwork/disable-2.webp");
            width: 100%;
            aspect-ratio:  1 / 1;
        }




      }

      .disabled-right {
        width: 70%;
        background: var(--baiege);
        border-radius: 10px;

        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        h3 {
          text-align: center;
        }

        .counter {
          width: 100%;
          background: var(--darkbaiege);
          border-radius: 10px;
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;

          &:last-child {
            flex-direction: row-reverse;
          }

          .count-box {
            width: fit-content;
            padding: 20px;
            background: var(--baiege);
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .independent-disabled {
    .independent-disable-container {
      .disabled-bottom{
        display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      .disabled-left {
        width: 90% !important;
        padding: 0px 20px;
      }
      .disabled-right {
        width: 90% !important;
      }
      }
    }
  }
}

.independent-poor {
  .independent-poor-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    .poor-cards {
      width: 100%;
      display: flex;
      gap: 20px;
      align-items: stretch;
      background: var(--darkbaiege);
      padding: 20px;
      border-radius: 10px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .independent-poor {
    .independent-poor-container {
      .poor-cards {
        display: flex;
        flex-wrap: wrap !important;
      }
    }
  }
}

.empower-carousel {
  background: var(--darkbaiege);

  .empower-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .carousel-box {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;
    }
  }
}
