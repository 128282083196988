.education-slider .education-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.education-slider .education-slider-container .slider-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  overflow: hidden !important;
  margin: 0px 10px !important;
}

.education-independent {
  background: var(--darkbaiege);
}
.education-independent .education-independent-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: stretch !important;
}
.education-independent .education-independent-container > * {
  width: calc(50% - 10px);
}

@media only screen and (max-width: 1200px) {
  .education-independent-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .education-independent-container > * {
    width: 100% !important;
  }
}/*# sourceMappingURL=Education.css.map */