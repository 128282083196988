.blog-template .blog-template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.blog-template .blog-template-container .blog-top {
  display: flex;
  align-items: stretch;
  gap: 20px;
  width: 100%;
}
.blog-template .blog-template-container .blog-top .blog-img {
  width: 60%;
  height: auto;
  aspect-ratio: 2/1;
  background: red;
}
.blog-template .blog-template-container .blog-top .blog-stats {
  width: 40%;
  background: var(--darkbaiege);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.blog-template .blog-template-container .blog-top .blog-stats .stats {
  width: 100%;
  background: var(--baiege);
  border-radius: 10px;
  padding: 20px;
}
.blog-template .blog-template-container .blog-top .blog-stats .stats .property {
  color: var(--darkorange);
}
.blog-template .blog-template-container .blog-top .blog-stats .btn-active {
  padding: 20px 40px;
  margin-top: 20px;
}
.blog-template .blog-template-container .blog-bottom {
  padding: 20px;
  border-radius: 10px;
  background: var(--darkbaiege);
  width: 100%;
}
.blog-template .blog-template-container .blog-bottom .line {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.249);
  margin: 10px 0px;
}
@media only screen and (max-width: 1000px) {
  .blog-top {
    flex-direction: column;
  }
  .blog-top .blog-img {
    width: 100% !important;
  }
  .blog-top .blog-stats {
    width: 100% !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .blog-top .blog-stats .stats {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .blog-top .blog-stats .btn-active {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 800px) {
  .blog-top {
    flex-direction: column;
  }
  .blog-top .blog-stats {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .blog-top .blog-stats .stats {
    width: 100% !important;
  }
}/*# sourceMappingURL=blog_template.css.map */