.janjagar-conservation-slider .janjagar-conservation-slider-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.janjagar-conservation-slider .janjagar-conservation-slider-container .janjagar-slider-box {
  width: 100%;
  height: auto;
  background: var(--darkbaiege);
  padding-bottom: 0px;
  border-radius: 10px;
}

.janjagar-slider .janjagar-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.janjagar-slider .janjagar-slider-container .slider-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  overflow: hidden !important;
  margin: 0px 10px !important;
}/*# sourceMappingURL=janjagar.css.map */