.art-slider-box .swiper {
  width: 100%;
  height: 100%;
}
.art-slider-box .swiper-slide {
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 10px 10px 30px 10px;
  gap: 10px;
}
.art-slider-box .swiper-slide .card-list {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.art-slider-box .swiper-slide .img-box {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.art-slider-box .swiper-slide .img-box .img {
  background: orange;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 4/3;
}
.art-slider-box .autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--darkorange);
}
.art-slider-box .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--darkorange);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

@media only screen and (max-width: 1100px) {
  .art-slider-box .swiper-slide {
    flex-direction: column !important;
  }
  .art-slider-box .swiper-slide .card-list, .art-slider-box .swiper-slide .img-box {
    width: 100% !important;
  }
  .art-slider-box .swiper-slide .img-box .img {
    aspect-ratio: 16/9 !important;
  }
}/*# sourceMappingURL=art--slider.css.map */