.privacy-parent {
  padding: 5px 10px;
}
.privacy-parent .privacy > * {
  padding: 10px 0px;
}
.privacy-parent .privacy > * h4 {
  font-weight: bold;
}
.privacy-parent .privacy {
  max-width: var(--swl_d);
  margin: 100px auto 15px auto;
}
.privacy-parent .privacy h1 {
  text-align: center;
}
.privacy-parent .privacy .suggest-text {
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.726) !important;
  font-size: 17px;
}/*# sourceMappingURL=privacy.css.map */