.janjagar-slider-box {
    h2 {
        text-align: center;
        padding: 20px;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 10px 10px 30px 10px;
        gap: 10px;


        .card-list {
            width: 40%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            p {
                padding: 0px 0px 20px 0px !important;
            }

        }

        .img-box {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;

            .img {
                background: orange;
                border-radius: 10px;
                width: 100%;
                aspect-ratio: 4 / 3;
            }
        }
    }

    .autoplay-progress {
        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 10;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: var(--darkorange);
    }

    .autoplay-progress svg {
        --progress: 0;
        position: absolute;
        left: 0;
        top: 0px;
        z-index: 10;
        width: 100%;
        height: 100%;
        stroke-width: 4px;
        stroke: var(--darkorange);
        fill: none;
        stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
        stroke-dasharray: 125.6;
        transform: rotate(-90deg);
    }
}

@media only screen and (max-width: 1100px) {
    .janjagar-slider-box {
        .swiper-slide {
            flex-direction: column !important;

            .card-list,
            .img-box {
                width: 100% !important;
            }

            .img-box {
                .img {
                    aspect-ratio: 16 / 9 !important;
                }
            }
        }

    }
}