.header {
    height: 60px;
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 0px;
    padding: 0px 10px;

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 100px;
        padding: 0px 10px !important;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.405);

        .logo {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                height: 70%;
                padding-bottom: 5px;
            }
        }

        .links {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 30px;

            a:not(.btn-active) {
                text-decoration: none;
                font-weight: 400;
                color: var(--white);
                font-size: 18px;
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .submenu-parent {
                    padding-top: 20px;
                    position: absolute;
                    top: 50px;
                    transition: var(--trans);
                    height: 0px;


                    .submenu {
                        position: relative;
                        padding: 0px;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 15px;
                        backdrop-filter: blur(10px) !important;
                        transition: var(--trans);
                        z-index: 1001;
                        height: 0px;
                        opacity: 0;
                        overflow: hidden;
                        


                        a {
                            white-space: nowrap;
                            font-size: 0px;
                            transition: var(--trans);

                        }

                        a::after {
                            top: 110%;
                            transform: translateX(0%);
                        }
                    }
                }


                &::after {
                    background: var(--darkorange);
                    content: "";
                    display: block;
                    width: 0px;
                    height: 3px;
                    border-radius: 10px;
                    position: absolute;
                    top: 80%;
                    left: 0px;
                    transform: translateX(-5%);
                    transition: var(--trans2);

                }

                &:hover {
                    transition: var(--trans);

                    &::after {
                        width: calc(100% + 10px);
                        transition: var(--trans);
                    }

                    .submenu-parent {
                        display: block;

                        .submenu {
                            padding: 15px;
                            height: auto;
                            opacity: 1;

                            a {
                                font-size: 16px;
                            }
                        }
                    }

                }
            }

            a.active {
                color: var(--darkorange);
            }
        }
    }
}


.burger-menu {
    position: absolute;
    top: 15px;
    height: 50px;
    width: 50px;
    z-index: 10001;
    right: 20px;
    display: none;

    // margin-right: 50px;
    .hamburger {
        transition: translate 1s, rotate 1s;
    }

    &[aria-expanded="true"] .hamburger {
        translate: 3px -3px;
        rotate: 0.125turn;
    }

    .line {
        transition: 1s;
        stroke-dasharray: 60 31 60 300;
    }

    &[aria-expanded="true"] .line {
        stroke-dashoffset: -90;
        stroke-dasharray: 60 105 60 300;
    }
}

@media only screen and (max-width: 1100px) {
    .header {
        .header-container {
            .links {
                display: none;
            }
        }
    }

    .burger-menu {
        display: block;
    }
}

.mobile-nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background: #000000d7;
    right: -100%;
    transition: all 1s ease;
    opacity: 0;

    .mobile-nav-container {
        width: 250px;
        height: calc(100svh - 30px);
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 10px;
        transition: cubic-bezier(.57,.21,.69,1.25) all 1s;
        right: -400px;

        .phone-links {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            padding: 150px 0px 0px 20px;
            gap: 10px;

            a {
                text-decoration: none;
                font-weight: 400;
                color: var(--white);
                font-size: 18px;
                position: relative;
            }

            .submenu-mob {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 20px;
                height: 0px;
                overflow: hidden !important;
                transition: var(--trans);
            }

            .submenu-accor {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 20px;
                overflow: hidden;
                transition: var(--trans);
                height: 200px;
                justify-content: space-evenly;

            }

            .submenu-accor2 {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 10px;
                overflow: hidden;
                transition: var(--trans);
                height: 100px;
                justify-content: space-evenly;

            }
        }
    }
}

.mobile-nav-toggled {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background: #000000bd;
    right: 0%;
    transition: all 1s ease;
    opacity: 1;


    .mobile-nav-container {
        width: 250px;
        height: 90%;
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 10px;
        transition: cubic-bezier(.57,.21,.69,1.25) all 1s;;
        height: calc(100svh - 30px);
        display: flex;

        .phone-links {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            padding: 150px 0px 0px 0px;
            gap: 15px;
            align-items: center;

            a {
                text-decoration: none;
                font-weight: 400;
                color: var(--white);
                font-size: 18px;
                position: relative;
                padding-left: 20px;
                width: 100%;
            }

            .a {
                text-decoration: none;
                font-weight: 400;
                color: var(--white);
                font-size: 18px;
                position: relative;
                padding-left: 20px;
                width: 100%;

            }

            .submenu-mob {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 20px;
                height: 0px;
                overflow: hidden !important;
                transition: var(--trans);
            }

            .submenu-accor {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 20px;
                overflow: hidden;
                transition: var(--trans);
                height: 250px;
                justify-content: space-evenly;
                margin-left: -20px;
                margin: 5px 0px 5px -20px;
                border-radius: 10px;

                a {
                    font-size: 16px;
                }

                background: #0000007e;
            }

            .submenu-accor2 {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 0px 20px;
                overflow: hidden;
                transition: var(--trans);
                height: 100px;
                justify-content: space-evenly;
                margin: 5px 0px 5px -20px;
                border-radius: 10px;

                a {
                    font-size: 16px;
                }

                background: #0000007e;
            }

            .btn-active {
                width: fit-content;
                margin-top: 50px;
            }
        }

    }

    .mobile-right {
        width: calc(100% - 250px);
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .mobile-nav-toggled {
        
        .mobile-nav-container {
            width: calc(80% - 30px);
            .phone-links 
            {
                padding-top: 40px;
                overflow: hidden;
            }
        }

        .mobile-right {
            width: 0%;
        }
    }
}