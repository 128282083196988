.blog-template {
    .blog-template-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .blog-top {
            display: flex;
            align-items: stretch;
            gap: 20px;
            width: 100%;

            .blog-img {
                width: 60%;
                height: auto;
                aspect-ratio: 2 / 1;
                background: red;
            }

            .blog-stats {
                width: 40%;
                background: var(--darkbaiege);
                border-radius: 10px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;

                .stats {
                    width: 100%;
                    background: var(--baiege);
                    border-radius: 10px;
                    padding: 20px;

                    .property {
                        color: var(--darkorange);
                    }
                }

                .btn-active {
                    padding: 20px 40px;
                    margin-top: 20px;
                }
            }
        }


        .blog-bottom {
            padding: 20px;
            border-radius: 10px;
            background: var(--darkbaiege);

width: 100%;
            .line {
                width: 100%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.249);
                margin: 10px 0px;
            }

            .blog-body-container {}
        }
    }
}

@media only screen and (max-width:1000px) {

    .blog-top {
        flex-direction: column;

        .blog-img {
            width: 100% !important;
        }

        .blog-stats {
            width: 100% !important;
            flex-direction: row !important;
            align-items: center !important;

            .stats {
                width: fit-content !important;
            }

            .btn-active {

                margin-top: 0px !important;
            }
        }
    }


}

@media only screen and (max-width:800px) {
    .blog-top {
        flex-direction: column;

        .blog-stats {
            width: 100% !important;
            flex-direction: column !important;
            align-items: center !important;

            .stats {
                width: 100% !important;
            }
        }
    }
}