.carousel-container-about {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px !important;

    .mySwiper {
        width: 100%;
        height: 100%;

        .slide {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }

        .slideabout1 {
            background: url("../Img/video.webp") !important;
        }

        .slideabout2 {
            background: url("../Img/video.webp") !important;
        }

        .slideabout3 {
            background: url("../Img/video.webp") !important;
        }
    }
}