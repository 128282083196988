.ourwork-carousel-container {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px !important;
    overflow: hidden;

    .mySwiper {
        width: 100%;
        height: 100%;

        .slide {
            width: 100%;
            height: 100%;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }

        .slidework1 {
            background: url("../Img/pt-ab.webp") !important;
        }

        .slidework2 {
            background: url("../Img/pt-ab.webp") !important;
        }

        .slidework3 {
            background: url("../Img/pt-ab.webp") !important;
        }

        .slidework4 {
            background: url("../Img/pt-ab.webp") !important;
        }

        .slidework5 {
            background: url("../Img/pt-ab.webp") !important;
        }
    }
}