.health-independent{
    background: var(--darkbaiege);
    .health-independent-container{
        display: flex;
        align-items: stretch;
        gap: 20px;

      
    }

}
.health-independent2{

    .health-independent2-container{
        display: flex;
        align-items: stretch;
        gap: 20px;

        .health2-right{
            background: var(--darkbaiege);
            display: flex;
            flex-direction: column;
            p:not(:last-child){
                padding-bottom: 20px;
            }
        }
        .health2-left{
            background: var(--darkbaiege);
         
        }
        
    }

}



.health-carousel {
    background: var(--darkbaiege);
    

    .health-carousel-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .carousel-box {

            width: 100%;
            height: auto;
            aspect-ratio: 2 / 1;



        }
    }
}