.video-gallery-parent {
    background-color: var(--darkbaiege);
    height: auto;
padding: 20px;
    .video-gallery-container {
        height: auto;
        width: 100%;
        max-width: var(--swl_d);
        margin: auto;
        .video-gallery-box {
            display: flex;
            flex-wrap: wrap;
           
            width: 100%;
            height: 100%;
            gap: 30px;
            align-items: center;
            justify-content: center;
        

            .first-video {
                overflow: hidden;
                border-radius: 10px !important;
                height: 250px !important;
                width: 350px !important;

                .video_player {
                    border-radius: 10px !important;
                    width: 100% !important;
                    height: 100% !important;
                }

            }
        }
    }

   
}



@media only screen and (max-width:1200px){

   
 
    .video-gallery-parent {
        
    height: auto;
    width: 100%;
    
        
}
}