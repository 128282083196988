.carousel-container-home {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px !important;
  .mySwiper {
    width: 100%;
    height: 100%;

    .slide {
      width: 100%;
      height: 100%;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      position: relative;
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.908));
        top: 0;
        left: 0;
        z-index: 10;
      }
      .slide-inner-container {
        height: 100%;
        display: flex;
        align-items: flex-end;
        .slide-content-box {
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 20px;
          z-index: 11;

          h1 {
            color: white;
          }
          p {
            color: rgba(255, 255, 255, 0.692);
          }
        }
      }
    }

    .slideh1 {
      background: url("../Img/home-page/slider-1.webp");
    }

    .slideh2 {
      background: url("../Img/home-page/slider-2.webp");
    }

    .slideh3 {
      background: url("../Img/home-page/slider-5.webp");
    }
    .slideh4 {
      background: url("../extra/_MG_1578.webp");
    }
    .slideh5 {
      background: url("../Img/home-page/slider-4.webp");
    }
  }
}
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (min-width: 1920px) {
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 800px) {
  .slideh1 {
    background: url("../Img/mobile-home-slider/slider-1-mob.webp") !important;
  }
  // 

  .slideh2 {
    background: url("../Img/mobile-home-slider/slider-2-mob.webp") !important;
  }

  .slideh3 {
    background: url("../Img/mobile-home-slider/slider-5-mob.webp") !important;
  }
  .slideh4 {
    background: url("../Img/mobile-home-slider/slider-3-mob.webp") !important;
  }
  .slideh5 {
    background: url("../Img/mobile-home-slider/slider-4-mob.webp") !important;
  }
  
}
