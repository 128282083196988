* {
  // border: 1px solid blueviolet;
}

.progresscard-wrapper {
  height: auto;
  background-color: var(--darkbaiege);

  .progress-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    h2 {
      padding-bottom: 30px;
    }

    .stats-container {
      width: 100%;
      height: 750px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      .stats-top,
      .stats-bottom {
        display: flex;
        align-items: center;
        height: 50%;
        width: 100%;
        gap: 20px;

        &.stats-bottom {
          flex-direction: row-reverse;
        }

        .stats-image1 {
          background: url("../Img/gallery-page/03.webp");
          height: 100%;
          width: 60%;
          border-radius: 10px;
        }
        .stats-image2 {
          background: url("../Img/gallery-page/26.webp");
          height: 100%;
          width: 60%;
          border-radius: 10px;
        }

        .stats-cards {
          height: 100%;
          width: 40%;
          background-color: var(--baiege);
          padding: 20px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          border-radius: 10px;

          .progress-bar {
            width: 100%;
            height: auto;
            margin-top: 30px;

            .progress-bg {
              height: 5px;
              width: 100%;
              border-radius: 100px;
              background: var(--darkbaiege);
              position: relative;

              .progress-line {
                height: inherit;
                background: var(--darkorange);
                border-radius: 100px;
              }

              .progress-dot {
                position: absolute;
                top: 50%;
                background: var(--darkorange);
                height: 15px;
                width: 15px;
                border-radius: 50%;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.363);
                transform: translate(-50%, -50%);

                &::before {
                  content: attr(progress);
                  transform: translate(-30%, -150%);
                  font-weight: 600;
                  color: var(--darkorange);
                  display: block;
                }
              }
            }
          }

          .stats-numbers {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .stats-current,
            .stats-target {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &.stats-target {
                align-items: flex-end;
              }

              .stats-indicator {
                font-size: 14px;
                color: var(--text);
              }

              .stats-values {
                font-weight: 700;
              }
            }

            .stats-arrow {
              display: flex;
              font-size: 30px;
              opacity: 0.1;
            }
          }

          .stats-buttons {
            display: flex;
            align-items: baseline;
            gap: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .progresscard-wrapper {
    .progress-card-container {
      .stats-container {
        height: auto;
        .stats-top,
        .stats-bottom {
          height: 450px !important;

          .stats-image {
            width: 50%;
          }
          .stats-cards {
            width: 50%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .progresscard-wrapper {
    .progress-card-container {
      .stats-container {
        .stats-top,
        .stats-bottom {
          flex-direction: column !important;
          height: auto;
          width: 100% !important;

          .stats-image,
          .stats-cards {
            height: 400px;
            width: 100% !important;
          }
          .stats-image {
            width: 100% !important;
          }
          .stats-image1,
          .stats-image2 {
            width: 100% !important;
            height: 250px !important;
          }
        }
      }
    }
  }
}
