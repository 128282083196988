.ourwork-carousel-container {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px !important;
  overflow: hidden;
}
.ourwork-carousel-container .mySwiper {
  width: 100%;
  height: 100%;
}
.ourwork-carousel-container .mySwiper .slide {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.ourwork-carousel-container .mySwiper .slidework1 {
  background: url("../Img/pt-ab.webp") !important;
}
.ourwork-carousel-container .mySwiper .slidework2 {
  background: url("../Img/pt-ab.webp") !important;
}
.ourwork-carousel-container .mySwiper .slidework3 {
  background: url("../Img/pt-ab.webp") !important;
}
.ourwork-carousel-container .mySwiper .slidework4 {
  background: url("../Img/pt-ab.webp") !important;
}
.ourwork-carousel-container .mySwiper .slidework5 {
  background: url("../Img/pt-ab.webp") !important;
}/*# sourceMappingURL=ourworkcarousel.css.map */