
.privacy-parent{
    padding: 5px 10px;
.privacy>* {
    padding: 10px 0px;
h4{
    font-weight: bold;
}

}

.privacy {
    max-width: var(--swl_d);
    margin: 100px auto 15px auto;
h1{
    text-align: center;
}
//     p{
//         font-family: "Inter", sans-serif;
// font-size: 16px;
// color:#000000;
//     }



    .suggest-text {
        font-weight: bold!important;
        color: rgba(0, 0, 0, 0.726)!important;
               font-size: 17px;
        // display: flex;
       

        // span {
        //     font-size: 14px;
        // }
    }

    
}
}