.environment-independent {
  background: var(--darkbaiege);

  .environment-independent-container {
    display: flex;
    align-items: stretch;
    gap: 20px;

    .environment-left {
      width: 60%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .tree-count {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .environment-right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      .environment-img {
        border-radius: 10px;
        background: url("../Img/ourwork/en-2.webp");
        width: 80%;
        aspect-ratio: 1 / 1;
      }
    }
  }
}

.environment-independent2 {
  .environment-independent2-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.environment-independent3 {
  background: var(--darkbaiege);

  .environment-independent3-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .environment3-main {
      width: 100%;
      display: flex;
      gap: 20px;

      .environment3-left {
        width: 60%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
      }

      .environment3-right {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;

        .environment3-img {
          border-radius: 10px;
          background: url("../Img/ourwork/en-3.webp");
          width: 80%;
          aspect-ratio: 1 / 1;
        }
      }
    }

    .environment3-stats {
      width: 100%;
      display: flex;
      gap: 20px;
    }
    .environment4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px;

      p,
      h2 {
        text-align: center;
      }
    }
  }
}

.environment-carousel {
  .environment-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .carousel-box {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;
    }
  }
}
@media only screen and (max-width: 800px) {
  .environment-independent {
    .environment-independent-container {
      display: flex;
      flex-direction: column-reverse;

      .environment-left {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
      .environment-right {
        width: 100% !important;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .environment-independent3 {
    .environment-independent3-container {
      display: flex;
      flex-direction: column;

      .environment3-main {
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        align-items: center;
        .environment3-left {
          width: 100% !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
        .environment3-right {
          width: 100% !important;
        }
      }
      .environment3-stats {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        .stats1 {
          width: 95%;
        }
        .stats2 {
          width: 95%;
        }
      }
    }
  }
}
