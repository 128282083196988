.carousel-container-about {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px !important;
}
.carousel-container-about .mySwiper {
  width: 100%;
  height: 100%;
}
.carousel-container-about .mySwiper .slide {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.carousel-container-about .mySwiper .slideabout1 {
  background: url("../Img/video.webp") !important;
}
.carousel-container-about .mySwiper .slideabout2 {
  background: url("../Img/video.webp") !important;
}
.carousel-container-about .mySwiper .slideabout3 {
  background: url("../Img/video.webp") !important;
}/*# sourceMappingURL=carousel.css.map */