.donate-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 150px 0px;
  background: var(--darkbaiege);
}
.donate-parent .donate-container {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  width: 100%;
}
.donate-parent .donate-container .big-button-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
}
.donate-parent .donate-container .big-button-box .big-button {
  text-decoration: none !important;
  height: auto;
  aspect-ratio: 2/1;
  width: 300px;
  border-radius: 15px;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  transition: var(--trans2);
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton1 {
  background: url("../Img/gallery-page/04.webp");
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton2 {
  background: url("../Img/gallery-page/26.webp");
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton3 {
  background: url("../Img/gallery-page/50.webp");
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton4 {
  background: url("../Img/gallery-page/03.webp");
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton5 {
  background: url("../Img/donate/medical2.webp");
}
.donate-parent .donate-container .big-button-box .big-button.bigbutton6 {
  background: url("../Img/gallery-page/environment.webp");
}
.donate-parent .donate-container .big-button-box .big-button h3 {
  z-index: 10;
  text-decoration: none !important;
  color: var(--white);
  position: absolute;
  bottom: 15px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  transition: var(--trans2);
}
.donate-parent .donate-container .big-button-box .big-button:hover {
  transform: scale(1.15);
  transition: var(--trans);
}
.donate-parent .donate-container .big-button-box .big-button:hover h3 {
  bottom: 50%;
  transition: var(--trans);
  transform: translateX(-50%) translateY(30%) scale(1.2);
}
.donate-parent .donate-container .big-button-box .big-button::after {
  height: auto;
  width: 100%;
  display: block;
  content: "";
  background: linear-gradient(0deg, black, transparent);
  position: absolute;
  top: 0px;
  left: 0px;
  aspect-ratio: 2/1;
  transition: var(--trans2);
}

@media only screen and (max-width: 1200px) {
  .big-button-box {
    width: 100% !important;
    flex-direction: column;
  }
  .big-button-box .big-button {
    width: 60% !important;
    height: 200px !important;
    position: relative;
  }
  .big-button-box .big-button::after {
    height: 200px !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .big-button-box {
    width: 100% !important;
    flex-direction: column;
  }
  .big-button-box .big-button {
    width: 100% !important;
    height: 200px !important;
    position: relative;
  }
  .big-button-box .big-button::after {
    height: 200px !important;
    width: 100% !important;
  }
}/*# sourceMappingURL=donate.css.map */