.searchbox-wrapper {
  height: auto;
  background: var(--darkbaiege);

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  .searchbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .searchbox {
      border: 2px solid rgba(128, 128, 128, 0.541);
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--baiege);
      border-radius: 100px;

      input {
        width: 95%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 18px;
        color: var(--text);
        border-radius: 200px;
      }

      span {
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 26px;
          color: var(--text);
        }
      }
    }

    .search-tools {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px;

      input {
        border: 2px solid rgba(128, 128, 128, 0.541);
        border-radius: 30px;
        padding: 5px 10px;
        background: var(--baiege);
        width: 250px;
        height: 40px !important;
      }

      select {
        appearance: none;
        outline: 0;
        box-shadow: 0;
        background: var(--baiege);
        background-image: none;
        width: 250px;
        border: 2px solid rgba(128, 128, 128, 0.541) !important;
        border-radius: 30px;
        padding: 5px 10px;
        height: 40px !important;
      }

      option {
        background: var(--darkbaiege);
        padding: 10px 10px !important;
        outline: none !important;
        height: 40px !important;
      }

      .blog-btn-box,
      .blog-input-box {
        display: flex;
        height: 100%;
        gap: 20px;
      }
    }
  }
}
.blogsresult-parent {
  .blogsresult-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .search-tools {
    input,
    select {
      width: 200px !important;
    }
  }
}
@media only screen and (max-width: 900px) {
  .search-tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input,
    select {
      width: 45% !important;
      height: 40px !important;
    }


    .blog-btn-box,
    .blog-input-box {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px !important;
        margin: 10px 0px;
    }
  }
}

.searchbox-wrapper{
    padding: 40px 0px;
}

.blog-end-btn {
  margin-top: 50px;
}
