.home-carousel-container {
  width: 100%;
  height: auto;
  aspect-ratio: 192 / 70;
}

.activity {
  h2 {
    text-align: center;
    padding-bottom: 30px;
  }

  .activity-container {
    .mySwiper {
      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-bottom: 50px;
      }
    }
  }
}

.about-parent {
  height: 800px;
  background-color: var(--baiege);

  .about-container {
    display: flex;

    .about-left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .box-parent {
        width: 512px;
        aspect-ratio: 1 / 1;
        position: relative;

        .box-bg {
          position: absolute;
          top: 0;
          left: 0;
          height: 40%;
          aspect-ratio: 1 / 1;
          background: #000;
          background: url("../Img/gallery-page/42.webp");
        }

        .bigbox {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 60%;
          aspect-ratio: 1 / 1;
          transform: translate(-50%, -50%);
          background-color: greenyellow;
          background: url("../Img/gallery-page/39.webp");
        }

        .smallbox {
          position: absolute;
          top: 33%;
          left: 420px;
          height: 30%;
          aspect-ratio: 1 / 1;
          background-color: plum;
          background: url("../Img/gallery-page/44.webp");
          z-index: 9;
        }

        .blurbox {
          position: absolute;
          top: 65%;
          left: 65%;
          height: 30%;
          aspect-ratio: 1 / 1;
          z-index: 10;
          border-radius: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding: 20px;

          .accent-div {
            background: var(--darkorange);
            height: 50px;
            width: 50px;
            border-radius: 10px;
          }
        }
      }
    }

    .about-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      width: 50%;
      height: 100%;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .about-parent {
    .about-container {
      .about-left {
        justify-content: center;

        .box-parent {
          width: 400px;

          .smallbox {
            left: 335px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .about-parent {
    .about-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .about-left {
        justify-content: center;
        align-items: center;
        width: 100%;

        .box-parent {
          width: 500px;

          .smallbox {
            left: 410px !important;
          }
        }
      }
    }

    .about-right {
      width: 100% !important;
      text-align: center;
      align-items: center !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .about-parent {
    .about-container {
      .about-left {
        padding: 20px !important;

        .box-parent {
          width: 400px;
          aspect-ratio: 1 / 1 !important;

          .smallbox {
            left: 330px !important;
          }
        }
      }
    }

    .about-right {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  .about-parent {
    .about-container {
      .about-left {
        .box-parent {
          width: 300px;
          aspect-ratio: 1 / 1 !important;

          .smallbox {
            left: 250px !important;
          }
        }
      }
    }

    .about-right {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 430px) {
  .about-parent {
    .about-container {
      .about-left {
        padding: 0px !important;

        .box-parent {
          width: 250px;
          aspect-ratio: 1 / 1 !important;

          .smallbox {
            left: 210px !important;
          }
        }
      }
    }

    .about-right {
      width: 100% !important;
    }
  }
}

.latestblog-div {
  h2 {
    text-align: center;
    padding-bottom: 50px;
  }
  .latestblog-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

  }
}
@media only screen and (max-width: 1100px) {
  .latestblog-div {
    .latestblog-container {
      flex-direction: column;
      align-items: center;

      .latestblog-left {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .home-carousel-container {
    aspect-ratio: none;
    height: 700px;
  }
}
@media only screen and (max-width: 1300px) {
  .home-carousel-container {
    aspect-ratio: none;
    height: 500px;
  }
}
@media only screen and (max-width: 600px) {
  .home-carousel-container {
    aspect-ratio: none;
    height: 100svh;
  }
}
