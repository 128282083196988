.footer-parent {
  padding: 10px 0px 30px 0px;
  background: rgb(20, 20, 20);
}
.footer-parent .footer-container {
  max-width: var(--swl_d);
  height: 100%;
  margin: auto;
  padding: 10px 20px 0px 20px;
}
.footer-parent .footer-container .footer-top {
  display: flex;
  justify-content: space-around;
}
.footer-parent .footer-container .footer-top .col {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-parent .footer-container .footer-top .col .title {
  color: var(--white);
  padding-bottom: 10px;
}
.footer-parent .footer-container .footer-top .col a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.342);
  font-size: 14px;
}
.footer-parent .footer-container .footer-top .col a:hover {
  color: var(--darkorange);
}
.footer-parent .footer-container .footer-top .column {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-parent .footer-container .footer-top .column .title {
  color: var(--white);
  padding-bottom: 10px;
  font-weight: bold !important;
}
.footer-parent .footer-container .footer-top .column a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.342);
  font-size: 14px;
}
.footer-parent .footer-container .footer-top .column a:hover {
  color: var(--darkorange);
}
.footer-parent .footer-container .footer-top .col4 .icon-address {
  display: flex;
  gap: 20px;
}
.footer-parent .footer-container .footer-top .col4 .icon-address .f-icon1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 20px;
  border-radius: 50%;
  align-items: center;
  padding: 8px;
  color: var(--darkorange);
}

.footer-bottom-parent {
  background-color: black;
  padding: 30px 10px;
  height: auto;
}
.footer-bottom-parent .footer-bottom-container {
  height: 100%;
  max-width: var(--swl_d) !important;
  margin: auto !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.footer-bottom-parent .footer-bottom-container .social {
  display: flex;
  gap: 30px;
  font-size: 25px;
}
.footer-bottom-parent .footer-bottom-container .social .f-icon {
  color: var(--text) !important;
}
.footer-bottom-parent .footer-bottom-container .social .f-icon:hover {
  color: var(--darkorange) !important;
}
.footer-bottom-parent .footer-bottom-container .reserved {
  text-align: center;
  color: rgba(255, 255, 255, 0.356);
}
.footer-bottom-parent .footer-bottom-container .reserved a {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

@media only screen and (max-width: 600px) {
  .footer-parent {
    height: auto;
  }
  .footer-parent .footer-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-parent .footer-container .footer-top {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
  }
  .footer-parent .footer-container .footer-top .col {
    align-items: center;
    width: 100% !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.129);
    padding: 20px 0px;
  }
  .footer-parent .footer-container .footer-top .column {
    width: 100% !important;
    padding: 20px 0px;
  }
  .footer-bottom-parent {
    height: auto;
  }
  .footer-bottom-parent .footer-bottom-container {
    overflow: hidden;
    text-align: center;
    height: auto;
  }
}
@media only screen and (max-width: 1000px) {
  .footer-parent .footer-container {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-parent .footer-container .footer-top {
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }
  .footer-parent .footer-container .footer-top .col {
    width: 45%;
  }
  .footer-parent .footer-container .footer-top .column {
    width: 45%;
  }
  .footer-parent .footer-container .footer-bottom {
    width: 100%;
  }
}
@media only screen and (max-width: 530px) {
  .footer-parent .footer-container .footer-top .column {
    width: 100% !important;
    padding: auto 50px !important;
  }
}/*# sourceMappingURL=footer.css.map */