.hook-parent {
height: auto;
background-color: var(--darkbaiege);
    .hook-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}