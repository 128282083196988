.herocard-container {
    height: auto;
    padding: 50px 0px;

    .herocard-inner-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .herocard-left {
            height: 100%;
            padding: 20px;
            width: 50%;

            .img-container {
                .shine {
                    position: relative;
                    overflow: hidden;
                    width: 512px;
                    height: 512px;

                    &::before {
                        background: linear-gradient(to right,
                                fade_out(#fff, 1) 0%,
                                fade_out(#fff, 0.7) 100%);
                        content: "";
                        display: block;
                        height: 100%;
                        left: -75%;
                        position: absolute;
                        top: 0;
                        transform: skewX(-25deg);
                        width: 50%;
                        z-index: 2;
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            animation: shine 0.85s;
                        }
                    }

                    @keyframes shine {
                        100% {
                            left: 125%;
                        }
                    }
                }
            }

        }

        .herocard-right {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: justify;
            padding: 10px;
            width: 50%;

            .right-head {
                width: 100%;
                padding: 10px 0px;
                text-align: left;

            }

            .right-desc {}
        }
    }
}

@media only screen and (max-width: 1200px) {
    .herocard-container {
        .herocard-inner-container {

            .herocard-right {
                width: 55% !important;

                .right-head {}
            }

            .herocard-left {
                width: 45% !important;
                align-items: center;
                justify-content: center;
                display: flex;

                .imgbg {
                    width: 400px !important;
                    height: 400px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .herocard-container {
        .herocard-inner-container {
            flex-direction: column;

            .herocard-right {
                width: 100% !important;

                .right-head {
                    text-align: center;
                }
            }

            .herocard-left {
                width: 100% !important;
                align-items: center;
                justify-content: center;
                display: flex;

                .imgbg {
                    height: auto !important;
                    aspect-ratio: 1 / 1;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .herocard-container {
        .herocard-inner-container {


            .herocard-left {


                .imgbg {
                    height: 320px !important;
                    width: 320px !important;
                }
            }
        }
    }
}