// #root > div > div.activity.parent > div > div > div.swiper-wrapper > div.swiper-slide.swiper-slide-active.slide.slide1 
// {

// }
.activity-container
{
    background-color: var(--darkbaiege);
padding: 20px !important;
border-radius: 10px;


}
#root > div > div.activity.parent > div > div > div.swiper-wrapper .card-body
{

    height: 550px !important;
}




#root > div > div.art-year-slider.parent > div > div > div > div.swiper-button-next {

    display: none !important;

    
}
#root > div > div.art-year-slider.parent > div > div > div > div.swiper-button-prev {

    display: none !important;

    
}
#root > div > div.founder.parent > div > div > div  {
   height:600px !important;


}

@media only screen and (max-width : 1000px) {
#root > div > div.independent-disabled.parent > div > div.disbaled-left > h2{
    text-align: center !important;
    width: 100%;
}
}
#root > div > div.independent-empower.parent > div > div.independent-left > p{
    text-align: justify;
}









