.health-independent {
  background: var(--darkbaiege);
}
.health-independent .health-independent-container {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.health-independent2 .health-independent2-container {
  display: flex;
  align-items: stretch;
  gap: 20px;
}
.health-independent2 .health-independent2-container .health2-right {
  background: var(--darkbaiege);
  display: flex;
  flex-direction: column;
}
.health-independent2 .health-independent2-container .health2-right p:not(:last-child) {
  padding-bottom: 20px;
}
.health-independent2 .health-independent2-container .health2-left {
  background: var(--darkbaiege);
}

.health-carousel {
  background: var(--darkbaiege);
}
.health-carousel .health-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.health-carousel .health-carousel-container .carousel-box {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1;
}/*# sourceMappingURL=health.css.map */