.largeblogcard-container 
{
    width: 500px;
    padding: 5px 15px;
    .largecard-img
    {
        // background: url("https://img.freepik.com/free-photo/night-sky-glows-with-iridescent-deep-space-generative-ai_188544-11285.jpg?w=1380&t=st=1702447923~exp=1702448523~hmac=a203ab7e1518871b73deb0ae6030abfc44f187af8edfe4da6322e8023fc9a014");
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100%;
        aspect-ratio: 2 / 1;
        border-radius: 10px;
    }
    .category-box 
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.171);
        .category {
            font-size: 12px;
            color: var(--darkorange);
            font-weight: 700;
        }
        
        .published {
            font-size: 12px;

            span {
                color: var(--darkorange);
                font-weight: 700;
            }
        }
    }
}




@media only screen and (max-width: 1200px) {
    .largeblogcard-container {
        width: 100% !important;
        background: red !important;
    }
  }