.cookies-parent{
    padding: 5px 10px;
.cookies>* {
overflow: hidden!important;
    padding: 10px 0px;
h4{
    font-weight: bold;
}

}

.cookies {
    max-width: var(--swl_d);
    margin: 100px auto 15px auto;
h1{
    text-align: center;
}

.suggest-text {
    font-weight: bold!important;
    color: rgba(0, 0, 0, 0.726)!important;
           font-size: 17px;
}
}
}