.beforefooter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0px;
  background: var(--darkbaiege);

  .beforefooter-container {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    width: 100%;
    .big-button-box {
      display: flex;
      align-items: center;
      gap: 30px;
      justify-content: center;

      .big-button {
        text-decoration: none !important;
        height: 200px;
        width: 200px;
        border-radius: 15px;
        padding-bottom: 20px;
        overflow: hidden;
        position: relative;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important ;
        transition: var(--trans2);

        &.bigbutton1 {
          background: url("../Img/d1.webp");
        }
        &.bigbutton2 {
          background: url("../Img/c1.webp");
        }
        &.bigbutton3 {
          background: url("../Img/v1.webp");
        }

        h4 {
          z-index: 10;
          text-decoration: none !important;
          color: var(--white);
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          transition: var(--trans2);
        }
        &:hover {
          transform: scale(1.15);
          transition: var(--trans);

          h4 {
            bottom: 50%;
            transition: var(--trans);
            transform: translateX(-50%) translateY(30%) scale(1.2);
          }
          
        }

        &::after {
          height: inherit;
          width: inherit;
          display: block;
          content: "";
          background: linear-gradient(0deg, black, transparent);
          position: absolute;
          top: 0px;
          left: 0px;
          transition: var(--trans2);
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .big-button-box {
    width: 100% !important;
    flex-direction: column;
    .big-button {
      width: 60% !important;
      height: 200px !important;
      position: relative;
      &::after {
        height: 200px !important;
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .big-button-box {
    width: 100% !important;
    flex-direction: column;
    .big-button {
      width: 100% !important;
      height: 200px !important;
      position: relative;
      &::after {
        height: 200px !important;
        width: 100% !important;
      }
    }
  }
}
