.media-parent {
  background-color: var(--darkbaiege);
  height: auto;
}
.media-parent .media-gallery-c {
  max-width: var(--swl_d);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.media-parent .media-gallery-c h1 {
  margin: 10px 0px;
  font-size: 1.9rem;
  padding: 2px;
  font-weight: 800;
}
.media-parent .media-gallery-c h1 span {
  font-size: 1.8rem;
  color: var(--accent);
}

.image-card {
  border-radius: 10px;
  margin-top: 70px;
  width: 350px;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 15px;
}

#lightbox-img {
  margin-top: 40px;
  height: 70vh;
  max-width: 80vw;
  -o-object-fit: cover;
     object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img:hover,
.gallery-btn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .media-parent {
    height: auto;
  }
}/*# sourceMappingURL=media.css.map */