.drushti-parent {
  background-color: var(--darkbaiege);
}
.drushti-parent .drushti-container {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drushti-parent .drushti-container .dbox-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  height: auto;
  width: 100%;
}
.drushti-parent .drushti-container .dbox-parent .dimg1-box {
  height: 250px !important;
  width: 250px !important;
  background: url("../Img/gallery-page/media/drushti1.webp");
  border-radius: 10px;
}
.drushti-parent .drushti-container .dbox-parent .dimg1-box .doverlay {
  height: 100%;
  width: inherit;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.829));
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}
.drushti-parent .drushti-container .dbox-parent .dimg1-box .doverlay h3 {
  color: white;
  text-align: center;
}
.drushti-parent .drushti-container .dbox-parent .second-img {
  background: url("../Img/gallery-page/media/drushti2.webp");
}
.drushti-parent .drushti-container .dbox-parent .third-img {
  background: url("../Img/gallery-page/media/drushti3.webp");
}
.drushti-parent .drushti-container .dbox-parent .fourth-img {
  background: url("../Img/gallery-page/media/drushti4.webp");
}
@media only screen and (max-width: 1100px) {
  .drushti-parent .drushti-container {
    max-width: 600px !important;
  }
}/*# sourceMappingURL=drushti.css.map */